import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Tabs.scss";
import { AppContext } from "../../utils/context";

const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState("radio-1");
  const { recommendNumber } = useContext(AppContext);
  const history = useNavigate();
  const location = useLocation();

  const handleTabChange = (tab, route) => {
    setSelectedTab(tab);
    history(route);
  };

  useEffect(() => {
    switch (location?.pathname) {
      case "/":
        setSelectedTab("radio-1");
        break;
      case "/running-now":
        setSelectedTab("radio-2");
        break;
      case "/upcoming":
        setSelectedTab("radio-3");
        break;
      case "/top-rated":
        setSelectedTab("radio-4");
        break;
      default:
        setSelectedTab("");
        break;
    }
  }, [location?.pathname]);

  return (
    <div className="tab-wrapper">
      <div className="tabs">
        <input
          type="radio"
          id="radio-1"
          name="tabs"
          checked={selectedTab === "radio-1"}
          onChange={() => handleTabChange("radio-1", "/")}
        />
        <label className="tab" htmlFor="radio-1">
          Recommends<span className="notification">{recommendNumber}</span>
        </label>
        <input
          type="radio"
          id="radio-2"
          name="tabs"
          checked={selectedTab === "radio-2"}
          onChange={() => handleTabChange("radio-2", "/running-now")}
        />
        <label className="tab" htmlFor="radio-2">
          Now running
        </label>
        <input
          type="radio"
          id="radio-3"
          name="tabs"
          checked={selectedTab === "radio-3"}
          onChange={() => handleTabChange("radio-3", "/upcoming")}
        />
        <label className="tab" htmlFor="radio-3">
          Upcoming
        </label>
        <input
          type="radio"
          id="radio-4"
          name="tabs"
          checked={selectedTab === "radio-4"}
          onChange={() => handleTabChange("radio-4", "/top-rated")}
        />
        <label className="tab" htmlFor="radio-4">
          Top rated
        </label>
      </div>
    </div>
  );
};

export default Tabs;
