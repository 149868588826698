import { useEffect, useState, useContext } from "react";
import FavItem from "../favItem/FavItem";
import "./Recommend.scss";
import { Circles } from "react-loading-icons";
import axios from "axios";
import { useNavigate, useMatch } from "react-router-dom";
import RecommendList from "components/recommendList/RecommendList";
import { AppContext } from "../../utils/context";

const Recommend = ({
  keyword,
  setKeyword,
  favList,
  setFavList,
  handleFavourite,
  isLoading,
  onFavItemDelete,
  setDbFavCheck,
}) => {
  const history = useNavigate();
  let hashed = useMatch("/recommend/:hash");
  const { addRecommendNumber } = useContext(AppContext);

  const [favNum, setFavNum] = useState(0);
  const [favCheck, setFavCheck] = useState(false);
  const [dbFavList, setDbFavList] = useState([]);
  const [dbRecListArray, setDbRecListArray] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [isNowLoading, setNowLoading] = useState(isLoading);
  const [isDbData, setIsDbData] = useState(false);

  const checkFav = (fav) => {
    setFavCheck(fav);
  };

  // useEffect(() => {
  //   checkFav();
  //   setFavNum(mainList.length);
  // }, [favCheck, favNum]);

  var recId = hashed?.params?.hash;

  const callGetRecMoviesApi = async () => {
    setNowLoading(true);
    try {
      let apiUrl = `https://www.zoet.app/zoetapp/api.php`;

      if (recId) {
        apiUrl = `https://www.zoet.app/zoetapp/api.php?user_id=${recId}`;
      }

      const response = await axios.get(apiUrl);

      if (response.data && response.data.list) {
        addRecommendNumber(response?.data?.list?.length);
        setDbRecListArray(response.data.list);
      } else {
        setDbRecListArray([]); // Set to empty array if no data found
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setNowLoading(false);
    }
  };

  useEffect(() => {
    callGetRecMoviesApi();
  }, [recId]);

  // useEffect(() => {
  //   callGetRecMoviesApi();
  // }, []);
  return (
    <>
      <div className="fav-container mb-4 d-flex justify-content-center align-items-center">
        <div className="fav-header">
          <h2>Latest Recommendations</h2>
        </div>
        {true && (
          <div className="fav-clear mx-2">
            <a
              href="#!"
              className="d-flex align-items-center"
              onClick={() => callGetRecMoviesApi()}
            >
              <i className="bi bi-arrow-repeat  font-weight-bold"></i>
            </a>
          </div>
        )}
      </div>

      {dbRecListArray && (
        <RecommendList
          isNowLoading={isNowLoading}
          setNowLoading={setNowLoading}
          dbRecListArray={dbRecListArray}
          keyword={keyword}
        />
      )}
    </>
  );
};

export default Recommend;
