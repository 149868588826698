import FavItem from "components/favItem/FavItem";
import SkeletonItem from "components/skeletonItem/SkeletonItem";
import { useEffect } from "react";

export const ErrorMessage = ({ keyword }) => {
  return (
    <>
      <div className="col-sm-12 text-center error-messages">
        <h4>Your Favorites List is Empty!</h4>
        <h5 className="inspire-text">
          Choose your top picks and share the love with your friends!
          <i className="bi bi-emoji-smile-fill py-2 d-block"></i>
        </h5>
      </div>
    </>
  );
};

const FavouriteList = ({
  isNowLoading,
  mainList,
  keyword,
  handleFavourite,
  setFavCheck,
  onFavItemDelete,
  onDeleteItem,
  isDbData,
  setNowLoading,
}) => {
  useEffect(() => {
    if (mainList && mainList.length !== 0) {
      setNowLoading(false);
    }
  }, [mainList, isNowLoading]);

  return (
    <>
      {isNowLoading ? (
        <SkeletonItem />
      ) : mainList && mainList.length <= 0 ? (
        <ErrorMessage keyword={keyword} />
      ) : mainList && mainList.length > 0 ? (
        mainList.map((movie) => (
          <FavItem
            key={movie.imdbID}
            movieData={movie}
            mainList={mainList}
            keyword={keyword}
            handleFavourite={handleFavourite}
            setFavCheck={setFavCheck}
            onFavItemDelete={onFavItemDelete}
            isDbData={isDbData}
            onDeleteItem={onDeleteItem}
          />
        ))
      ) : (
        <ErrorMessage keyword={keyword} />
      )}
    </>
  );
};

export default FavouriteList;
