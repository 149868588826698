import "./Search.scss";
import { useNavigate, Link, useMatch, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { RWebShare } from "react-web-share";
import Popped from "components/modal/Popped";
import axios from "axios";
import { Circles } from "react-loading-icons";
import Tabs from "components/tabs/Tabs";

const SearchBar = ({
  keyword,
  setKeyword,
  results,
  setResults,
  clear,
  setClear,
  favList,
  chanceValue,
  sendBtns,
  dbFavCheck,
}) => {
  const history = useNavigate();
  const location = useLocation();
  let hashed = useMatch("/favourite/:hash");

  const searchInputRefOne = useRef(null);
  const searchInputRefTwo = useRef(null);
  const scrollRef = useRef();

  const [tipData, setTipData] = useState("");
  const [sendBtn, setSendBtn] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const focus = () => {
    searchInputRefTwo.current.focus();
  };

  const handleScroll = () => {
    var body = document.body;

    // var calHeight = Math.max(
    //   body.scrollHeight,
    //   body.offsetHeight,
    //   html.clientHeight,
    //   html.scrollHeight,
    //   html.offsetHeight
    // );
    const offset = scrollRef.current.getBoundingClientRect().height;
    if (body.scrollTop >= offset) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    document.querySelector("body").addEventListener("scroll", handleScroll);
    return () =>
      document
        .querySelector("body")
        .removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    setSendBtn(sendBtns);
  }, [sendBtns]);

  useEffect(() => {
    var dat = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : [];
    setTipData(dat.length);

    if (dat.length > 0 && history?.location?.pathname !== "/") {
      setSendBtn(false);
    }
  }, [favList, history, location]);

  useEffect(() => {
    if (favList.length === 0) {
      setSendBtn(false);
    }
  }, [tipData, favList]);

  useEffect(() => {
    if (dbFavCheck && favList.length !== 0) {
      setSendBtn(true);
    }
  }, [dbFavCheck, favList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setKeyword(keyword);
    history("/");
    if (history?.location?.pathname !== "/") {
      history("/");
    }
    setSendBtn(false);
  };

  const handleHome = (e) => {
    e.preventDefault();
    setKeyword(keyword);
    history("/");
    if (history?.location?.pathname !== "/") {
      history("/");
    }
    setSendBtn(false);
  };

  const onBlurGroup = () => {
    window.setTimeout(() => {
      if (
        document.activeElement === searchInputRefOne.current ||
        document.activeElement === searchInputRefTwo.current
      ) {
        return;
      }
    }, 0);
  };

  const handleClear = () => {
    setKeyword("");
    setResults([]);
    localStorage.removeItem("keyword");
    localStorage.removeItem("results");
    setClear(true);
    if (history?.location?.pathname !== "/") {
      history("/");
    }
    setSendBtn(false);
  };

  const handleInputKeyword = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
    setResults([]);
    if (history && history?.location?.pathname !== "/") {
      history("/");
    }
    // setSendBtn(true);
  };

  // Push to api

  const submitEvent = async () => {
    var favData = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : [];

    var favId = hashed?.params?.hash;
    const data = {
      user_id: favId,
      fav_id: favId,
      movies: favData,
      location_lat: 0,
      location_long: 120,
    };

    try {
      const res = await axios.post(
        "https://www.zoet.app/zoetapp/api.php",
        data,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleFavShare = () => {
    submitEvent();
  };

  return (
    <>
      {scrolled && scrolled && (
        <div className={"search-container d-flex sticky-search"}>
          <div className="container d-flex flex-column">
            <div className="row text-center">
              <div className="col-sm-12">
                <form onSubmit={handleSubmit}>
                  <div
                    className={"d-flex flex-row align-items-center flex-nowrap"}
                  >
                    <div className="input-group flex-fill my-3 column flex-nowrap">
                      <input
                        className="search-input"
                        placeholder="Type a movie name"
                        required=""
                        value={keyword}
                        onChange={(e) => {
                          handleInputKeyword(e);
                          focus();
                        }}
                        ref={searchInputRefOne}
                        onBlur={onBlurGroup}
                      />
                      <div className="input-group-append">
                        <button
                          id="button-addon2"
                          type="submit"
                          className="btn btn-primary prime-btn search-btn"
                          onClick={
                            keyword.trim() !== "" ? handleClear : handleSubmit
                          }
                        >
                          <i
                            className={`${keyword.trim() !== "" ? "bi bi-x-lg" : "bi bi-search"}`}
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div
                      className={"text-center d-flex justify-content-center"}
                    >
                      <button
                        className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                        onClick={handleHome}
                        title="Home"
                      >
                        <i className="bi bi-house"></i>
                      </button>
                      {sendBtn ? (
                        <RWebShare
                          data={{
                            text: `Dude, This is an awesome favourite movie list of mine. Do watch all.`,
                            url: `https://www.zoet.app/recommend/${
                              hashed === null ? "404" : hashed?.params?.hash
                            }`,
                            title: "Zoet",
                          }}
                          onClick={handleFavShare}
                        >
                          <button
                            type="button"
                            className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                            title={`Share ${tipData} ${
                              tipData > 1 ? "movies" : "movie"
                            }`}
                          >
                            {false ? (
                              <Circles />
                            ) : (
                              <i className="bi bi-share"></i>
                            )}
                          </button>
                        </RWebShare>
                      ) : (
                        <a
                          className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                          title={`${tipData} ${
                            tipData > 1 ? "movies" : "movie"
                          }`}
                          href={`/favourite/${chanceValue}/`}
                        >
                          <i className="bi bi-suit-heart-fill"></i>
                        </a>
                      )}
                      {sendBtn ? <Popped setSendBtn={setSendBtn}></Popped> : ""}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={"search-container d-flex"} ref={scrollRef}>
        <div className="container d-flex flex-column">
          <div className={"row"}>
            <div className="col-sm-12 text-center mt-3 app-title-wrapper">
              <Link to="/">
                <h1 className="mb-3 app-title">zoet</h1>
              </Link>
              <p className="sub-heading mb-0">
                Search and mark your favourite movies. Head to the favorites (
                <i className="bi bi-suit-heart-fill"></i>) page and hit share (
                <i className="bi bi-share"></i>) to surprise your loved ones
                with your movie picks. Ta-da!
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-sm-12">
              <form onSubmit={handleSubmit}>
                <div
                  className={"d-flex flex-row align-items-center flex-nowrap"}
                >
                  <div className="input-group flex-fill my-3 flex-nowrap">
                    <input
                      className="search-input"
                      placeholder="Type a movie name"
                      required=""
                      value={keyword}
                      onChange={(e) => handleInputKeyword(e)}
                      ref={searchInputRefTwo}
                      onBlur={onBlurGroup}
                    />
                    <div className="input-group-append">
                      <button
                        id="button-addon2"
                        type="submit"
                        className="btn btn-primary prime-btn search-btn"
                        onClick={
                          keyword.trim() !== "" ? handleClear : handleSubmit
                        }
                      >
                        <i
                          className={`${keyword.trim() !== "" ? "bi bi-x-lg" : "bi bi-search"}`}
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div className={"text-center d-flex justify-content-center"}>
                    <button
                      className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                      onClick={handleHome}
                      title="Home"
                    >
                      <i className="bi bi-house"></i>
                    </button>

                    {sendBtn ? (
                      <RWebShare
                        data={{
                          text: `Dude, This is an awesome favourite movie list of mine. Do watch all.`,
                          url: `https://www.zoet.app/recommend/${
                            hashed === null ? "404" : hashed?.params?.hash
                          }`,
                          title: "Zoet",
                        }}
                        onClick={handleFavShare}
                      >
                        <button
                          type="button"
                          className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                          title={`Share ${tipData} ${
                            tipData > 1 ? "movies" : "movie"
                          }`}
                        >
                          {false ? (
                            <Circles />
                          ) : (
                            <i className="bi bi-share"></i>
                          )}
                        </button>
                      </RWebShare>
                    ) : (
                      <a
                        className="btn btn-primary prime-btn d-flex align-items-center ml-12"
                        title={`${tipData} ${tipData > 1 ? "movies" : "movie"}`}
                        href={`/favourite/${chanceValue}/`}
                      >
                        <i className="bi bi-suit-heart-fill"></i>
                      </a>
                    )}
                    {sendBtn ? <Popped setSendBtn={setSendBtn}></Popped> : ""}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row text-center">
            <div className={"d-flex mb-2 w-100"}>
              <Tabs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
