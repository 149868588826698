// React Router
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import Chance from "chance";
import iso3166 from "iso3166-1";
import locale2 from "locale2";

import Favourite from "./components/favourite/Favourite";
import MovieList from "./components/movieList/MovieList";
import MovieListCurated from "./components/movieListCurated/MovieListCurated";
import MovieDetails from "./components/movieDetails/MovieDetails";
import SearchBar from "./components/search/Search";
import NoMatchRoute from "components/404/404";
import Recommend from "components/recommend/Recommend";
import RecommendListDB from "components/recommendListDB/RecommendListDB";
import { ContextProvider } from "utils/context";

const getDatafromLS = () => {
  const data = localStorage.getItem("favouriteList");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const App = () => {
  let chance = new Chance();
  const [results, setResults] = useState([]);
  const [favList, setFavList] = useState([]);
  const [clear, setClear] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [chanceValue, setChanceValue] = useState(chance.hash({ length: 8 }));
  const [sendBtn, setSendBtn] = useState(true);
  const [dbFavCheck, setDbFavCheck] = useState(false);

  const [favourite, setFavourite] = useState(getDatafromLS());
  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;
  const tmdbApiKey = process.env.REACT_APP_TMDB_PRIVATE_KEY;
  const tmdbBaseUrl = "https://api.themoviedb.org/3/movie/";
  const currentYear = new Date().getFullYear();
  const country = iso3166.fromLocale(locale2).to2();
  const movieApi = async () => {
    setLoading(true);
    try {
      await axios
        .get(`https://www.omdbapi.com/?s=${keyword}&apikey=${omdbApiKey}`)
        .then((res) => {
          const stateResults = JSON.stringify(res.data.Search);
          localStorage.setItem("keyword", keyword);
          if (stateResults) {
            localStorage.setItem("results", stateResults);
          }

          setResults(res.data.Search);
          setLoading(false);
        });
    } catch (error) {
      setKeyword("");
      setResults([]);
    }
  };

  useEffect(() => {
    const favIds = JSON.parse(localStorage.getItem("favouriteList")) || [];

    const getMovies = (favIds) => {
      setLoading(true);
      return axios
        .get(`https://www.omdbapi.com/?i=${favIds}&apikey=${omdbApiKey}`)
        .then((resp) => {
          return resp.data;
        });
    };

    const getFavFromMovies = async () => {
      const inFavList = await Promise.all(favIds.map((mov) => getMovies(mov)));
      return inFavList;
    };

    getFavFromMovies().then((res) => {
      setFavList(res);
    });
  }, []);

  useEffect(() => {
    setKeyword(
      localStorage.getItem("keyword") !== null
        ? localStorage.getItem("keyword")
        : "",
    );
    setResults(
      localStorage.getItem("results") !== null ||
        localStorage.getItem("results") !== undefined
        ? JSON.parse(localStorage.getItem("results"))
        : [],
    );
  }, []);

  useEffect(() => {
    movieApi();
  }, [keyword]);

  const handleFavourite = (id) => {
    setFavourite([...favourite, id]);
  };

  // const deleteFavourite = (id) => {
  //   const filteredFavourite = favourite.filter((element, index) => {
  //     return element.movieID !== id;
  //   });
  //   setFavourite(filteredFavourite);
  // };

  // saving data to local storage
  useEffect(() => {
    var uniqueArr = Array.from(new Set(favourite));
    localStorage.setItem("favouriteList", JSON.stringify(uniqueArr));
  }, [favourite]);
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ContextProvider>
        <div className="d-flex flex-column w-100 h-100">
          <SearchBar
            results={results}
            setResults={setResults}
            keyword={keyword}
            setKeyword={setKeyword}
            clear={clear}
            setClear={setClear}
            favList={favList}
            chanceValue={chanceValue}
            sendBtns={sendBtn}
            dbFavCheck={dbFavCheck}
          ></SearchBar>
          <div className="container">
            <div className="row my-4 app-content-container">
              <Routes>
                {keyword && keyword ? (
                  <Route
                    exact
                    path="/"
                    element={
                      <MovieList
                        keyword={keyword}
                        setKeyword={setKeyword}
                        results={results}
                        setResults={setResults}
                        handleFavourite={handleFavourite}
                        isLoading={isLoading}
                      />
                    }
                  />
                ) : (
                  <Route
                    exact
                    path={`/`}
                    element={
                      <Recommend
                        keyword={keyword}
                        setKeyword={setKeyword}
                        favList={favList}
                        setFavList={setFavList}
                        handleFavourite={handleFavourite}
                        isLoading={isLoading}
                        onFavItemDelete={(count) => {
                          if (count === 0) {
                            setFavList([]);
                          }
                        }}
                        setDbFavCheck={setDbFavCheck}
                      />
                    }
                  />
                )}
                <Route
                  exact
                  path={`/favourite/:hash`}
                  element={
                    <Favourite
                      keyword={keyword}
                      setKeyword={setKeyword}
                      favList={favList}
                      setFavList={setFavList}
                      handleFavourite={handleFavourite}
                      isLoading={isLoading}
                      onFavItemDelete={(count) => {
                        if (count === 0) {
                          setFavList([]);
                        }
                      }}
                      setDbFavCheck={setDbFavCheck}
                    />
                  }
                />
                <Route
                  exact
                  path={`recommend/:hash`}
                  element={
                    <RecommendListDB
                      keyword={keyword}
                      setKeyword={setKeyword}
                      favList={favList}
                      setFavList={setFavList}
                      handleFavourite={handleFavourite}
                      isLoading={isLoading}
                      onFavItemDelete={(count) => {
                        if (count === 0) {
                          setFavList([]);
                        }
                      }}
                      setDbFavCheck={setDbFavCheck}
                    />
                  }
                />
                <Route
                  path="/title/:id"
                  element={
                    <MovieDetails
                      keyword={keyword}
                      setKeyword={setKeyword}
                      results={results}
                      setResults={setResults}
                      clear={clear}
                    />
                  }
                />
                <Route
                  exact
                  path="/upcoming"
                  element={
                    <MovieListCurated
                      keyword={keyword}
                      setKeyword={setKeyword}
                      results={results}
                      setResults={setResults}
                      handleFavourite={handleFavourite}
                      isLoading={isLoading}
                      apiUrl={`${tmdbBaseUrl}upcoming?api_key=${tmdbApiKey}&language=en-US&page=1&year=${currentYear}&primary_release_year=${currentYear}&region=${country}`}
                    />
                  }
                />
                <Route
                  exact
                  path="/top-rated"
                  element={
                    <MovieListCurated
                      keyword={keyword}
                      setKeyword={setKeyword}
                      results={results}
                      setResults={setResults}
                      handleFavourite={handleFavourite}
                      isLoading={isLoading}
                      apiUrl={`${tmdbBaseUrl}top_rated?api_key=${tmdbApiKey}&language=en-US&page=1&region=ISO-3166-1`}
                    />
                  }
                />
                <Route
                  exact
                  path="/running-now"
                  element={
                    <MovieListCurated
                      keyword={keyword}
                      setKeyword={setKeyword}
                      results={results}
                      setResults={setResults}
                      handleFavourite={handleFavourite}
                      isLoading={isLoading}
                      apiUrl={`${tmdbBaseUrl}now_playing?api_key=${tmdbApiKey}&language=en-US&page=1&year=${currentYear}&primary_release_year=${currentYear}&region=${country}`}
                    />
                  }
                />
                <Route path="/404" element={<NoMatchRoute />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </ContextProvider>
    </BrowserRouter>
  );
};

export default App;
