import "./SkeletonItem.scss";
import noPoster from "../../../src/images/no-poster.png";
import Skeleton from "react-loading-skeleton";
import { Circles } from "react-loading-icons";

const SkeletonItem = ({}) => {
  return (
    <>
      <div className="skeleton-item-container col-md-6 col-lg-3 col-sm-12 d-flex align-items-center justify-content-center">
        <div className="movie-card d-flex flex-column">
          <div className="movie-card-header">
            <div className="movie-card-img-wrapper embed-responsive skeleton-item-wrapper">
              <Skeleton style={{ height: "100%", position: "absolute" }} />
              <div className="loader">
                <div className="loader-filmstrip"></div>
              </div>
            </div>
          </div>
          <div className="movie-card-body d-flex flex-column flex-fill">
            <div className="mb-3">
              <div className="movie-card-title">
                <Skeleton delay={1} />
              </div>
              <div className="movie-card-year">
                <Skeleton delay={1} />
              </div>
            </div>
            <div className="movie-card-controls d-flex align-items-center mt-auto">
              <div className="movie-card-details-btn flex-fill d-flex">
                <Skeleton />
              </div>
              <div className="movie-card-fav-btn movie-card-action-btn">
                <Skeleton />
              </div>
              <div
                className="movie-card-share-btn movie-card-action-btn"
                style={{ width: "20px", marginLeft: "10px" }}
              >
                <Skeleton />
              </div>

              <div
                className="movie-card-screen-btn movie-card-action-btn"
                style={{ width: "20px", marginLeft: "10px" }}
              >
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonItem;
