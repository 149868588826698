import { useEffect, useState } from "react";
import FavItem from "../favItem/FavItem";
import "./RecommendListDB.scss";
import { Circles } from "react-loading-icons";
import axios from "axios";
import { useNavigate, useMatch } from "react-router-dom";
import FavouriteList from "components/favouriteList/FavouriteList";

const RecommendListDB = ({
  keyword,
  setKeyword,
  favList,
  setFavList,
  handleFavourite,
  isLoading,
  onFavItemDelete,
  setDbFavCheck,
}) => {
  let hashed = useMatch("/recommend/:hash");

  const [favNum, setFavNum] = useState(0);
  const [favCheck, setFavCheck] = useState(false);
  const [dbFavList, setDbFavList] = useState([]);
  const [dbFavListArray, setDbFavListArray] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [isNowLoading, setNowLoading] = useState(true);
  const [isDbData, setIsDbData] = useState(false);

  const checkFav = (fav) => {
    setFavCheck(fav);
  };

  useEffect(() => {
    checkFav();
    setFavNum(mainList.length);
  }, [favCheck, favNum]);

  var favId = hashed?.params?.hash;

  useEffect(() => {
    const callGetFavMoviesApi = async (favId) => {
      // const body = { favId: favId, movies: favData };
      setNowLoading(true);
      try {
        await axios
          .get(`https://www.zoet.app/zoetapp/api.php?fav_id=${favId}`)
          .then((response) => {
            if (response.data && response.data.movies) {
              const stateResults = response.data.movies;
              setDbFavListArray(stateResults);
              setIsDbData(true);
              setDbFavCheck(true);
              setNowLoading(false);
            }
            {
              setNowLoading(false);
              setMainList(favList);
            }
          });
      } catch (error) {
        console.log(error);
      }
    };
    if (favId) {
      callGetFavMoviesApi(favId);
    }
  }, [favId]);

  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;

  useEffect(() => {
    var favDbLis = dbFavListArray;

    const getDbMovies = (favDbLis) => {
      return axios
        .get(`https://www.omdbapi.com/?i=${favDbLis}&apikey=${omdbApiKey}`)
        .then((resp) => {
          return resp.data;
        });
    };

    const getDbFavFromMovies = async () => {
      const inFavList = await Promise.all(
        favDbLis.map((mov) => {
          return getDbMovies(mov.movieId);
        }),
      );
      return inFavList;
    };

    getDbFavFromMovies().then((res) => {
      setDbFavList(res);
      if (res.length > 0) {
        setMainList(res);
      }
    });
  }, [dbFavListArray]);

  return (
    <>
      {false && (
        <div className="fav-container mb-4 d-flex justify-content-between align-items-center">
          <div className="fav-header">
            <h2>
              My Favourites<span className="mx-1">({favNum})</span>
            </h2>
          </div>
          <div className="fav-clear">
            <a href="#!" className="d-flex align-items-center">
              <i className="bi bi-trash mx-2"></i>
              <span className="d-inline-block">Clear all</span>
            </a>
          </div>
        </div>
      )}
      {mainList && (
        <FavouriteList
          isNowLoading={isNowLoading}
          setNowLoading={setNowLoading}
          mainList={mainList}
          keyword={keyword}
          handleFavourite={handleFavourite}
          setFavCheck={setFavCheck}
          onFavItemDelete={onFavItemDelete}
          onDeleteItem={(itemList) => {
            setMainList(itemList);
          }}
          isDbData={isDbData}
        />
      )}
    </>
  );
};

export default RecommendListDB;
