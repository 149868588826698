import MovieItem from "../movieItem/MovieItem";
import "./MovieList.scss";
import { Circles } from "react-loading-icons";
import SkeletonItem from "components/skeletonItem/SkeletonItem";

const ErrorMessage = ({ keyword }) => {
  return (
    <>
      {keyword !== "" && (
        <div className="col-sm-12 text-center error-messages">
          <h4>Ooops !! There is no movie with that name.</h4>
          <h5 className="inspire-text">
            May be one day you can make a movie with that name.
            <i className="bi bi-emoji-smile py-2 d-block"></i>
          </h5>
        </div>
      )}
    </>
  );
};

const MovieList = ({
  keyword,
  results,
  setKeyword,
  setResults,
  handleFavourite,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <SkeletonItem />
      ) : results !== undefined ? (
        results &&
        results.map((movie, id) => (
          <MovieItem
            key={id}
            movieData={movie}
            keyword={keyword}
            setKeyword={setKeyword}
            setResults={setResults}
            handleFavourite={handleFavourite}
          ></MovieItem>
        ))
      ) : (
        <ErrorMessage keyword={keyword} />
      )}
    </>
  );
};

export default MovieList;
