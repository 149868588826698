import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Popped = ({ setSendBtn }) => {
  const history = useNavigate();
  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleFavListDelete = () => {
    window.localStorage.removeItem("favouriteList");
    window.localStorage.removeItem("results");
    setShow(false);
    if (history?.location?.pathname !== "/") {
      history("/");
    }
    setSendBtn(false);
  };

  return (
    <>
      <Button
        variant="btn btn-primary prime-btn d-flex align-items-center ml-12"
        onClick={handleShow}
        title={`Delete favourite list`}
      >
        <i className="bi bi-trash"></i>
      </Button>
      <Modal animation={false} show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to delete all your favourite movie list..huh?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-primary sec-btn" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="btn btn-primary prime-btn"
            onClick={handleFavListDelete}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Popped;
