import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import MovieItem from "../movieItem/MovieItem";
import "./MovieListCurated.scss";
import { Circles } from "react-loading-icons";
import SkeletonItem from "components/skeletonItem/SkeletonItem";

const ErrorMessage = ({ keyword }) => {
  return (
    <>
      {keyword !== "" && (
        <div className="col-sm-12 text-center error-messages">
          <h4>Ooops !! There is no movie with that name.</h4>
          <h5 className="inspire-text">
            May be one day you can make a movie with that name.
            <i className="bi bi-emoji-smile py-2 d-block"></i>
          </h5>
        </div>
      )}
    </>
  );
};

const MovieListCurated = ({
  keyword,
  results,
  setKeyword,
  setResults,
  handleFavourite,
  apiUrl,
}) => {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listType, setListType] = useState("");
  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;

  const location = useLocation();

  useEffect(() => {
    const fetchMovies = async () => {
      if (!apiUrl) return;

      try {
        setIsLoading(true);
        const response = await axios.get(apiUrl);
        const tmdbMovies = response.data.results;

        // Determine if we need to filter by current year
        const shouldFilterByYear =
          window.location.pathname.includes("upcoming") ||
          window.location.pathname.includes("running-now");

        // Fetch detailed movie info from OMDB for each movie
        const omdbPromises = tmdbMovies.map(async (movie) => {
          // Use movie.title or movie.original_title depending on availability
          const title = movie.title || movie.original_title;

          const omdbResponse = await axios.get(
            `https://www.omdbapi.com/?t=${encodeURIComponent(title)}&apikey=${omdbApiKey}`,
          );

          // Check if the OMDB response has an error message
          if (
            omdbResponse.data.Error &&
            omdbResponse.data.Error === "Movie not found!"
          ) {
            return null; // Return null for movies not found
          } else {
            return omdbResponse.data; // Return valid movie data
          }
        });

        // Wait for all OMDB requests to complete
        let omdbMovies = await Promise.all(omdbPromises);

        // Filter out movies that returned null (movie not found)
        omdbMovies = omdbMovies.filter((movie) => movie !== null);

        // If filtering by current year
        if (shouldFilterByYear) {
          const currentYear = new Date().getFullYear();
          omdbMovies = omdbMovies.filter(
            (movie) => new Date(movie.Released).getFullYear() === currentYear,
          );
        }

        setMovies(omdbMovies);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching movies:", error);
        setIsLoading(false);
      }
    };

    fetchMovies();
  }, [apiUrl]);

  useEffect(() => {
    switch (location?.pathname) {
      case "/upcoming":
        setListType("Upcoming Movies");
        break;
      case "/top-rated":
        setListType("Top Rated Movies");
        break;
      case "/running-now":
        setListType("Now showing in theatres near you");
        break;
      default:
        setListType("");
        break;
    }
  }, [location?.pathname]);

  return (
    <>
      <div className="fav-header mb-3 d-flex w-100 justify-content-center">
        <h2>{listType}</h2>
      </div>
      {isLoading ? (
        <SkeletonItem />
      ) : (
        movies.length > 0 &&
        movies.map((movie, id) => (
          <MovieItem
            key={id}
            movieData={movie}
            keyword={keyword}
            setKeyword={setKeyword}
            setResults={setResults}
            handleFavourite={handleFavourite}
          ></MovieItem>
        ))
      )}
    </>
  );
};

export default MovieListCurated;
