import { useEffect, useState } from "react";
import FavouriteList from "components/favouriteList/FavouriteList";
import axios from "axios";
import { useNavigate, useMatch } from "react-router-dom";

const Favourite = ({
  keyword,
  setKeyword,
  favList,
  setFavList,
  handleFavourite,
  isLoading,
  onFavItemDelete,
  setDbFavCheck,
}) => {
  const history = useNavigate();
  let hashed = useMatch("/favourite/:hash");

  const [favNum, setFavNum] = useState(0);
  const [favCheck, setFavCheck] = useState(false);
  const [dbFavList, setDbFavList] = useState([]);
  const [dbFavListArray, setDbFavListArray] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [isNowLoading, setNowLoading] = useState(true);
  const [isDbData, setIsDbData] = useState(false);

  const checkFav = (fav) => {
    setFavCheck(fav);
  };

  useEffect(() => {
    checkFav();
    setFavNum(favList.length);
  }, [favCheck, dbFavListArray]);

  var favId = hashed?.params?.hash;

  useEffect(() => {
    const callGetFavMoviesApi = async (favId) => {
      setNowLoading(true);
      try {
        const response = await axios.get(
          `https://www.zoet.app/zoetapp/api.php?fav_id=${favId}`,
        );
        if (response.data && response.data.movies) {
          const stateResults = response.data.movies;
          setDbFavListArray(stateResults);
          setIsDbData(true);
          setDbFavCheck(true);
          setNowLoading(false);
        } else {
          setNowLoading(false);
          setMainList(favList);
        }
      } catch (error) {
        console.log(error);
        setNowLoading(false);
      }
    };

    if (favId) {
      callGetFavMoviesApi(favId);
    }
  }, [favId, favList, setDbFavCheck]);

  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;

  useEffect(() => {
    var favDbLis = dbFavListArray;

    const getDbMovies = (movieId) => {
      return axios
        .get(`https://www.omdbapi.com/?i=${movieId}&apikey=${omdbApiKey}`)
        .then((resp) => resp.data);
    };

    const getDbFavFromMovies = async () => {
      const inFavList = await Promise.all(
        favDbLis.map((mov) => getDbMovies(mov.movieId)),
      );
      return inFavList;
    };

    getDbFavFromMovies().then((res) => {
      setDbFavList(res);
      if (res.length > 0) {
        setMainList(res);
      }
    });
  }, [dbFavListArray, omdbApiKey]);

  return (
    <>
      {true && (
        <div className="fav-container mb-4 d-flex justify-content-center align-items-center">
          <div className="fav-header">
            <h2>
              My Favourites<span className="mx-1">({favNum})</span>
            </h2>
          </div>
          {/* <div className="fav-clear">
            <a href="#!" className="d-flex align-items-center">
              <i className="bi bi-trash mx-2"></i>
              <span className="d-inline-block">Clear all</span>
            </a>
          </div> */}
        </div>
      )}
      <FavouriteList
        isNowLoading={isNowLoading}
        setNowLoading={setNowLoading}
        mainList={favList}
        keyword={keyword}
        handleFavourite={handleFavourite}
        setFavCheck={setFavCheck}
        onFavItemDelete={onFavItemDelete}
        onDeleteItem={(itemList) => {
          setMainList(itemList);
        }}
        isDbData={isDbData}
      />
    </>
  );
};

export default Favourite;
