import RecommendItem from "components/recommendItem/RecommendItem";
import SkeletonItem from "components/skeletonItem/SkeletonItem";
import { useEffect } from "react";
import { Circles } from "react-loading-icons";

const ErrorMessage = ({ keyword }) => {
  return (
    <>
      {keyword !== "" && (
        <div className="col-sm-12 text-center error-messages">
          <h4>There are no zoet list</h4>
          <h5 className="inspire-text">
            Collect your favourites and recommend to your friends
            <i className="bi bi-emoji-smile py-2 d-block"></i>
          </h5>
        </div>
      )}
    </>
  );
};

const RecommendList = ({ isNowLoading, dbRecListArray }) => {
  return (
    <>
      {isNowLoading ? (
        <SkeletonItem />
      ) : dbRecListArray ? (
        dbRecListArray?.map((movie) => {
          return (
            <RecommendItem
              key={movie.favId}
              movieData={movie}
              dbRecListArray={dbRecListArray}
              movieIds={movie.movies}
            ></RecommendItem>
          );
        })
      ) : (
        <ErrorMessage />
      )}
    </>
  );
};

export default RecommendList;
