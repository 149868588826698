import "./FavItem.scss";
import noPoster from "../../../src/images/no-poster.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { Helmet, HelmetProvider } from "react-helmet-async";

const FavItem = ({
  movieData,
  keyword,
  mainList,
  handleFavourite,
  setFavCheck,
  onDelete,
  isDbData,
  onDeleteItem,
  onFavItemDelete,
}) => {
  const [isFav, setIsFav] = useState(false);
  const history = useNavigate();
  const { Poster, Title, imdbID, Year } = movieData;

  useEffect(() => {
    let _favList = [];
    let ls_fav_list = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : undefined;
    if (ls_fav_list && ls_fav_list.length > 0) {
      _favList = [...ls_fav_list];
    }

    if (_favList.includes(imdbID)) {
      setIsFav(true);
    } else {
      setIsFav(false);
    }
  }, []);

  const handleFav = (id) => {
    setIsFav((prevState) => !prevState);
    // handleFav(imdbID)
    let _favList = [];
    let ls_fav_list = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : undefined;
    if (ls_fav_list && ls_fav_list.length > 0) {
      _favList = [...ls_fav_list];
    }

    if (_favList.includes(id)) {
      _favList = _favList.filter((favId) => favId !== id);

      if (!isDbData) {
        let _list = mainList.filter((a) => a.imdbID !== id);
        onFavItemDelete(_list.length);
        onDeleteItem(_list);
      }
    } else {
      _favList = [..._favList, id];
    }
    setFavCheck(isFav);
    window.localStorage.setItem("favouriteList", JSON.stringify(_favList));
  };

  useEffect(() => {
    const favList =
      JSON.parse(window.localStorage.getItem("favouriteList")) || [];
    setFavCheck(favList.includes(imdbID));
  }, [imdbID]);

  const handleDetails = () => {
    if (history?.location?.pathname !== "/") {
      history(`/title/${imdbID}`);
    }
  };

  return (
    <>
      <HelmetProvider>
        {movieData ? (
          <div className="col-md-6 col-lg-3 col-sm-12 d-flex align-items-center justify-content-center">
            <Helmet>
              <title>{`Zoet App - Movie Recommending App`}</title>

              <meta
                name="description"
                content="Search and recommend your favourite movie to your friends"
              />
              <meta itemprop="name" content="Zoet App" />
              <meta
                itemprop="description"
                content="Search and recommend your favourite movie to your friends"
              />
              <meta itemprop="image" content="zoet_logo-512x512.png" />
              <meta property="og:type" content="article" />
              <meta property="og:description" content={Title} />
              <meta property="og:url" content="https://www.zoet.app" />
              <meta
                property="og:image"
                itemprop="image"
                content={Poster && Poster !== "N/A" ? Poster : noPoster}
              />
              <meta
                property="og:image:secure_url"
                content={Poster && Poster !== "N/A" ? Poster : noPoster}
              />
              <meta property="og:image:width" content="256" />
              <meta property="og:image:height" content="256" />
              <meta property="og:site_name" content="https://www.zoet.app" />
              <meta property="article:section" content={Title} />
              <meta name="twitter:card" content="https://www.zoet.app" />
              <meta name="twitter:description" content={Title} />
              <meta name="twitter:title" content={Title} />
              <meta
                property="twitter:image"
                content={Poster && Poster !== "N/A" ? Poster : noPoster}
              ></meta>
              <meta name="theme-color" content="#553c8b" />
              <link
                itemprop="thumbnailUrl"
                href={Poster && Poster !== "N/A" ? Poster : noPoster}
              />
            </Helmet>
            <div className="movie-card d-flex flex-column">
              <div className="movie-card-header" onClick={handleDetails}>
                <div className="movie-card-img-wrapper embed-responsive">
                  <img
                    className="img-fluid embed-responsive-item embed-responsive-4by3"
                    src={Poster && Poster !== "N/A" ? Poster : noPoster}
                    alt={Title}
                  ></img>
                </div>
              </div>
              <div className="movie-card-body d-flex flex-column flex-fill">
                <div className="mb-3">
                  <div className="movie-card-title">{Title}</div>
                  <div className="movie-card-year">{Year}</div>
                </div>
                <div className="movie-card-controls d-flex align-items-center mt-auto">
                  <div className="movie-card-details-btn flex-fill d-flex">
                    <Link
                      to={{
                        pathname: `/title/${imdbID}`,
                        state: {
                          imdbIDNow: imdbID,
                        },
                      }}
                    >
                      <h3>See Details</h3>
                    </Link>
                  </div>
                  <div className="movie-card-fav-btn movie-card-action-btn">
                    <button onClick={() => handleFav(imdbID)}>
                      <i
                        className={
                          isFav ? "bi bi-suit-heart-fill" : "bi bi-suit-heart"
                        }
                      ></i>
                    </button>
                  </div>
                  <div className="movie-card-share-btn movie-card-action-btn">
                    <RWebShare
                      data={{
                        text: `Dude, watch this movie ${Title}`,
                        url: `https://www.zoet.app/title/${imdbID}`,
                        title: "Zoet",
                      }}
                    >
                      <i className="bi bi-share"></i>
                    </RWebShare>
                  </div>
                  {Poster && Poster !== "N/A" ? (
                    <div className="movie-card-screen-btn movie-card-action-btn">
                      <a
                        target="_blank"
                        href={Poster}
                        rel="noreferrer"
                        download
                      >
                        <i className="bi bi-card-image"></i>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </HelmetProvider>
    </>
  );
};

export default FavItem;
