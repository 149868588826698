import "./MovieDetails.scss";
import { useParams, useLocation, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import noPoster from "../../../src/images/no-poster.png";
import { RWebShare } from "react-web-share";
import Skeleton from "react-loading-skeleton";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getCode, getData } from "country-list";
import iso3166 from "iso3166-1";
import locale2 from "locale2";

const MovieDetails = ({ keyword, results, setKeyword, setResults, clear }) => {
  const location = useLocation();
  let hashedID = useMatch("/title/:id");
  const hashedValue = hashedID?.params?.hash;
  const { id } = useParams();
  const [movie, setMovie] = useState([]);
  const [tmdbData, setTmdbData] = useState([]);
  const [tmdbWatchResults, setTmdbWatchResults] = useState([]);
  const [isFav, setIsFav] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(false);
  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;
  const tmdbApiKey = process.env.REACT_APP_TMDB_PRIVATE_KEY;

  const movieDetailApi = async () => {
    try {
      const response = await axios.get(
        `https://www.omdbapi.com/?i=${id === null ? hashedValue : id}&apikey=${omdbApiKey}&plot=full`,
      );
      setMovie(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const movieTmdbApi = async (movieId) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${id === null ? hashedValue : id}?api_key=${tmdbApiKey}&language=en-US&external_source=imdb_id`,
      );
      setTmdbData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    movieDetailApi();
    movieTmdbApi(hashedValue || id);
  }, [keyword]);

  useEffect(() => {
    if (tmdbData.id) {
      axios
        .get(
          `https://api.themoviedb.org/3/movie/${id === null ? hashedValue : id}/watch/providers?api_key=e62a84deb09133aed7e77568fc0d4d72`,
        )
        .then((response) => {
          const country = iso3166.fromLocale(locale2).to2();
          const results = response.data.results;
          const countryProviders = results[country];
          setTmdbWatchResults(countryProviders);
        })
        .catch((error) => {
          console.log("Error fetching watch providers:", error);
          setTmdbWatchResults({});
        });
    }
  }, [tmdbData, hashedValue, id]);

  const {
    Poster,
    Title,
    Year,
    Genre,
    Plot,
    Writer,
    Director,
    Actors,
    Ratings,
    Language,
    Runtime,
    Country,
    Released,
    imdbID,
  } = movie;

  const { original_title, title, original_language } = tmdbData;

  const shortPlot =
    Plot && Plot.length >= 200 ? Plot.substring(0, 200) + "..." : Plot;

  useEffect(() => {
    let _favList = [];
    let ls_fav_list = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : undefined;
    if (ls_fav_list && ls_fav_list.length > 0) {
      _favList = [...ls_fav_list];
    }

    if (_favList.includes(hashedValue || id)) {
      setIsFav(true);
    } else {
      setIsFav(false);
    }
  }, []);

  const handleFav = () => {
    setIsFav((prevState) => !prevState);
    let _favList = [];
    let ls_fav_list = window.localStorage.getItem("favouriteList")
      ? JSON.parse(window.localStorage.getItem("favouriteList"))
      : undefined;
    if (ls_fav_list && ls_fav_list.length > 0) {
      _favList = [...ls_fav_list];
    }

    if (_favList.includes(imdbID)) {
      _favList = _favList.filter((a) => a !== imdbID);
    } else {
      _favList = [..._favList, imdbID];
    }
    window.localStorage.setItem("favouriteList", JSON.stringify(_favList));
  };

  let finalWatchList = [];
  if (tmdbWatchResults) {
    if (tmdbWatchResults.flatrate && tmdbWatchResults.flatrate.length > 0) {
      finalWatchList = tmdbWatchResults.flatrate;
    } else if (tmdbWatchResults.rent && tmdbWatchResults.rent.length > 0) {
      finalWatchList = tmdbWatchResults.rent;
    } else if (tmdbWatchResults.ads && tmdbWatchResults.ads.length > 0) {
      finalWatchList = tmdbWatchResults.ads;
    }
  }

  return (
    <HelmetProvider>
      <div className="d-flex align-items-center justify-content-center">
        <Helmet>
          <title>{`Zoet App - ${Title}`}</title>
          <meta
            name="description"
            content="Search and recommend your favourite movie to your friends"
          />
          <meta itemprop="name" content="Zoet App" />
          <meta
            itemprop="description"
            content="Search and recommend your favourite movie to your friends"
          />
          <meta itemprop="image" content="zoet_logo-512x512.png" />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={Title} />
          <meta property="og:url" content="https://www.zoet.app" />
          <meta
            property="og:image"
            itemprop="image"
            content={Poster && Poster !== "N/A" ? Poster : noPoster}
          />
          <meta
            property="og:image:secure_url"
            content={Poster && Poster !== "N/A" ? Poster : noPoster}
          />
          <meta property="og:image:width" content="256" />
          <meta property="og:image:height" content="256" />
          <meta property="og:site_name" content="https://www.zoet.app" />
          <meta property="article:section" content={Title} />
          <meta name="twitter:card" content="https://www.zoet.app" />
          <meta name="twitter:description" content={Title} />
          <meta name="twitter:title" content={Title} />
          <meta
            property="twitter:image"
            content={Poster && Poster !== "N/A" ? Poster : noPoster}
          />
          <meta name="theme-color" content="#553c8b" />
          <link
            itemprop="thumbnailUrl"
            href={Poster && Poster !== "N/A" ? Poster : noPoster}
          />
        </Helmet>
        {isLoading ? (
          <Skeleton width={300} height={450} />
        ) : (
          <div className="movie d-flex flex-wrap">
            <div className="movie-poster">
              <span className="movie-poster-fill">
                <img
                  src={Poster && Poster !== "N/A" ? Poster : noPoster}
                  alt={Title}
                />
              </span>
              <span className="movie-poster-featured">
                <img
                  src={Poster && Poster !== "N/A" ? Poster : noPoster}
                  alt={Title}
                />
              </span>
            </div>
            <div className="movie-details">
              <div className="movie-rating d-flex">
                {Ratings &&
                  Ratings.map((score, id) => (
                    <div
                      className={
                        score.Source === "Internet Movie Database"
                          ? "movie-rating-item-imdb movie-rating-item d-flex align-items-center"
                          : score.Source === "Metacritic"
                            ? "movie-rating-item-mc movie-rating-item d-flex align-items-center"
                            : score.Source === "Rotten Tomatoes"
                              ? "movie-rating-item-rt movie-rating-item d-flex align-items-center"
                              : "movie-rating-item d-flex align-items-center"
                      }
                      key={id}
                      title={score.Source}
                    >
                      <span>
                        {score.Source === "Internet Movie Database"
                          ? "IMDB"
                          : score.Source === "Metacritic"
                            ? "MC"
                            : score.Source === "Rotten Tomatoes"
                              ? "RT"
                              : score.Source}
                      </span>
                      <span>
                        {score.Source === "Internet Movie Database"
                          ? score.Value.substr(0, score.Value.indexOf("/"))
                          : score.Source === "Metacritic"
                            ? score.Value.substr(0, score.Value.indexOf("/"))
                            : score.Value}
                      </span>
                    </div>
                  ))}
              </div>
              <h2 className="movie-title">
                {Language &&
                Language.includes("English") &&
                original_language === "en"
                  ? Title
                  : (
                      <div>
                        {title && title ? (
                          <>
                            {title}
                            <span
                              style={{ fontSize: "1.2rem", marginLeft: "5px" }}
                            >
                              ({original_title})
                            </span>
                          </>
                        ) : (
                          Title
                        )}
                      </div>
                    ) || <Skeleton />}
              </h2>
              <ul className="movie-tags list-inline">
                <li className="movie-rated">{Language}</li>
                <li className="movie-year">{Year}</li>
                <li className="movie-year">{Genre}</li>
                <li className="movie-year">{Runtime}</li>
                <li className="movie-year">{Country}</li>
              </ul>
              <p className="movie-plot">
                {readMore ? Plot : shortPlot}
                <span
                  className="primary-link"
                  onClick={() => {
                    setReadMore(!readMore);
                  }}
                >
                  {readMore
                    ? "Read Less"
                    : Plot && Plot.length >= 200
                      ? "Read More"
                      : ""}
                </span>
              </p>
              <div className="movie-credits">
                <p>
                  <strong>Directed by:</strong> {Director}
                </p>
                <p>
                  <strong>Written by:</strong> {Writer}
                </p>
                <p>
                  <strong>Starring:</strong> {Actors}
                </p>
                <p>
                  <strong>Released:</strong> {Released}
                </p>
              </div>
              {finalWatchList && finalWatchList.length > 0 && (
                <div className="ott-wrapper d-flex mt-2 flex-column">
                  <div className="ott-label-wrapper">
                    <p>
                      <strong>Now Streaming on:</strong>
                    </p>
                  </div>
                  <div className="ott-img-container">
                    {finalWatchList.map((en, index) => (
                      <span
                        className={
                          en?.provider_name === "Hotstar"
                            ? "ott-icon-wrapper ott-icon-wrapper-hotstar"
                            : "ott-icon-wrapper"
                        }
                        key={index}
                        title={en?.provider_name}
                      >
                        <img
                          className="img-fluid"
                          src={`https://image.tmdb.org/t/p/w500/${en?.logo_path}`}
                          alt={en?.provider_name}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="movie-btn-wrapper d-flex align-items-center mt-4">
                <button
                  className="btn btn-primary sec-btn d-flex align-items-center"
                  onClick={handleFav}
                >
                  <i
                    className={
                      isFav ? "bi bi-suit-heart-fill" : "bi bi-suit-heart"
                    }
                  ></i>
                  <span>Favourite</span>
                </button>
                <RWebShare
                  data={{
                    text: `Dude, watch this movie ${Title}`,
                    url: `https://www.zoet.app/title/${imdbID}`,
                    title: "Zoet",
                  }}
                >
                  <button className="btn btn-primary prime-btn d-flex align-items-center ml-12">
                    <i className="bi bi-share"></i>
                    <span>Share</span>
                  </button>
                </RWebShare>
              </div>
            </div>
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default MovieDetails;
