import "./RecommendItem.scss";
import noPoster from "../../../src/images/no-poster.png";
import { Link, useNavigate, useLocation, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import moment from "moment";

const RecommendItem = ({ movieData, movieIds, onDelete, isDbData }) => {
  const history = useNavigate();
  let location = useLocation();
  const [dbRecList, setDbRecList] = useState([]);
  let hashed = useMatch("/recommend/:hash");
  var recId = hashed?.params?.hash;

  const omdbApiKey = process.env.REACT_APP_OMDB_PRIVATE_KEY;

  useEffect(() => {
    const getDbMovies = (favId) => {
      return axios
        .get(`https://www.omdbapi.com/?i=${favId}&apikey=${omdbApiKey}`)
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          console.error("Error fetching movie data:", error);
          return null;
        });
    };

    const favDbLis = movieIds;

    const getDbFavFromMovies = async () => {
      try {
        const inFavList = await Promise.all(
          favDbLis.map((favId) => getDbMovies(favId)),
        );
        return inFavList;
      } catch (error) {
        console.error("Error fetching movie data:", error);
        return [];
      }
    };

    getDbFavFromMovies().then((res) => {
      setDbRecList(res);
    });
  }, []);

  const handleRecommend = () => {
    if (history?.location?.pathname !== "/") {
      history(`/recommend/${movieData?.favId}`);
    }
  };

  return (
    <HelmetProvider>
      {movieData ? (
        <div className="col-md-6 col-lg-3 col-sm-12 d-flex align-items-center justify-content-center">
          <Helmet>
            <title>{`Zoet App - Movie Recommending App`}</title>
            <meta
              name="description"
              content="Search and recommend your favourite movie to your friends"
            />
            <meta itemprop="name" content="Zoet App" />
            <meta
              itemprop="description"
              content="Search and recommend your favourite movie to your friends"
            />
            <meta itemprop="image" content="zoet_logo-512x512.png" />
            <meta property="og:type" content="article" />
            <meta property="og:description" content="Title" />
            <meta property="og:url" content="https://www.zoet.app" />
            <meta property="og:image" itemprop="image" content={noPoster} />
            <meta property="og:image:secure_url" content={noPoster} />
            <meta property="og:image:width" content="256" />
            <meta property="og:image:height" content="256" />
            <meta property="og:site_name" content="https://www.zoet.app" />
            <meta property="article:section" content="Title" />
            <meta name="twitter:card" content="https://www.zoet.app" />
            <meta name="twitter:description" content="Title" />
            <meta name="twitter:title" content="Title" />
            <meta property="twitter:image" content={noPoster}></meta>
            <meta name="theme-color" content="#553c8b" />
            <link itemprop="thumbnailUrl" href={noPoster} />
          </Helmet>
          <div className="movie-card d-flex flex-column">
            <div
              className="movie-card-header rec-card-header"
              onClick={handleRecommend}
            >
              <div className="movie-card-img-wrapper rec-card-img-wrapper">
                <div className="recommend-img-grid w-100 h-100">
                  {dbRecList.length > 0 ? (
                    dbRecList.slice(0, 5).map((recMovie, index) => (
                      <div className="recommend-img-wrapper" key={index}>
                        <img
                          className="img-fluid w-100"
                          src={
                            recMovie.Poster && recMovie.Poster !== "N/A"
                              ? recMovie.Poster
                              : noPoster
                          }
                          alt="Movie Poster"
                        />
                      </div>
                    ))
                  ) : (
                    <img
                      className="img-fluid embed-responsive-item embed-responsive-4by3"
                      src={noPoster}
                      alt="No Poster Available"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="movie-card-body d-flex flex-column flex-fill">
              <div className="mb-3">
                <div className="movie-card-title">
                  Number of Movies: {movieData?.movies?.length}
                </div>
                <div className="movie-card-year">
                  Created on:{" "}
                  {moment(movieData?.createdAt).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className="movie-card-controls d-flex align-items-center mt-auto">
                <div className="movie-card-details-btn flex-fill d-flex">
                  <Link to={`/recommend/${movieData?.favId}`}>
                    <h3>See zoet List</h3>
                  </Link>
                </div>
                <div className="movie-card-share-btn movie-card-action-btn m-0">
                  <RWebShare
                    data={{
                      text: `Dude, watch this interesting movie List`,
                      url: `https://www.zoet.app/recommend/${movieData?.favId}`,
                      title: "Zoet",
                    }}
                  >
                    <i className="bi bi-share"></i>
                  </RWebShare>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>{"jagath"}</div>
      )}
    </HelmetProvider>
  );
};

export default RecommendItem;
