import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [recommendNumber, setRecommendNumber] = useState(0);

  const callGetRecMoviesApi = async () => {
    try {
      let apiUrl = `https://www.zoet.app/zoetapp/api.php`;

      const response = await axios.get(apiUrl);

      if (response.data && response.data.list) {
        addRecommendNumber(response?.data?.list?.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const addRecommendNumber = (num) => {
    setRecommendNumber(num);
  };

  useEffect(() => {
    callGetRecMoviesApi();
  }, []);

  return (
    <AppContext.Provider value={{ addRecommendNumber, recommendNumber }}>
      {children}
    </AppContext.Provider>
  );
};

export { ContextProvider, AppContext };
