import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";

const getDatafromLS = () => {
  const data = localStorage.getItem("favouriteList");
  if (data) {
    return JSON.parse(data);
  } else {
    return [];
  }
};

const NoMatchRoute = () => {
  const history = useNavigate();
  let hashed = useMatch("/favourite/:hash");

  useEffect(() => {
    var favId = hashed?.params?.hash;

    const fetchData = async () => {
      await axios
        .get(`https://www.zoet.app/zoetapp/api.php?user_id=${favId}`)
        .then((res) => history("/404"))
        .catch((err) => console.log(err));
    };
  }, []);
  return <div>404</div>;
};

export default NoMatchRoute;
